import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'utils/actionHelpers'
import { JOB_ACCEPT_TRANSITION, JOB_DECLINE_TRANSITION } from 'constants/job'

export const SET_ALL_JOBS_IS_LOADING = 'SET_ALL_JOBS_IS_LOADING'
export const JOBS = 'JOBS'
export const GET_COUNTER_JOB = createRequestTypes('GET_COUNTER_JOB')
export const RESET_ALL_JOBS = 'RESET_ALL_JOBS'
export const SET_CURRENT_JOB_ID = 'SET_CURRENT_JOB_ID'
export const SET_CURRENT_GEOLOC_PRO = 'SET_CURRENT_GEOLOC_PRO'
export const ESTIMATED_HOUR_MAP = 'ESTIMATED_HOUR_MAP'
export const GET_JOBS = 'GET_JOBS'
export const GET_JOB = createRequestTypes('GET_JOB')
export const GET_DIAGNOSTIC = createRequestTypes('GET_DIAGNOSTIC')
export const UPDATE_JOB = createRequestTypes('UPDATE_JOB')
export const GET_RECEIPT_JOB = createRequestTypes('GET_RECEIPT_JOB')
export const GET_RECEIPT_JOB_FILE = createRequestTypes('GET_RECEIPT_JOB_FILE')
export const OPEN_RECEIPT_JOB_FILE = 'OPEN_RECEIPT_JOB_FILE'
export const VALIDATE_RECEIPT = createRequestTypes('VALIDATE_RECEIPT')
export const GET_RECEIPT_SIGNATURE = createRequestTypes('GET_RECEIPT_SIGNATURE')
export const GET_INVOICE_JOB = createRequestTypes('GET_INVOICE_JOB')
export const TRANSITION_INVOICE_JOB = createRequestTypes(
  'TRANSITION_INVOICE_JOB',
)
export const JOBS_REQ = createRequestTypes(JOBS)
export const JOB_ACCEPT_TRANSITION_REQ = createRequestTypes(
  JOB_ACCEPT_TRANSITION,
)
export const JOB_DECLINE_TRANSITION_REQ = createRequestTypes(
  JOB_DECLINE_TRANSITION,
)
export const setAllJobsIsLoading = generateSimpleAction(SET_ALL_JOBS_IS_LOADING)
export const resetAllJobs = generateSimpleAction(RESET_ALL_JOBS)

export const jobs = generateRequestActions(JOBS_REQ)
export const jobAcceptTransition = generateRequestActions(
  JOB_ACCEPT_TRANSITION_REQ,
)
export const jobDeclineTransition = generateRequestActions(
  JOB_DECLINE_TRANSITION_REQ,
)
export const getJobs = generateSimpleAction(GET_JOBS)
export const setCurrentJobId = generateSimpleAction(SET_CURRENT_JOB_ID)
export const getJob = generateRequestActions(GET_JOB)
export const getDiagnostic = generateRequestActions(GET_DIAGNOSTIC)
export const getCounterJob = generateRequestActions(GET_COUNTER_JOB)
export const updateJob = generateRequestActions(UPDATE_JOB)
export const getReceiptJob = generateRequestActions(GET_RECEIPT_JOB)
export const getReceiptJobFile = generateRequestActions(GET_RECEIPT_JOB_FILE)
export const openReceiptJobFile = generateSimpleAction(OPEN_RECEIPT_JOB_FILE)
export const validateReceipt = generateRequestActions(VALIDATE_RECEIPT)
export const getReceiptSignature = generateRequestActions(GET_RECEIPT_SIGNATURE)
export const getInvoiceJob = generateRequestActions(GET_INVOICE_JOB)
export const transitionInvoiceJob = generateRequestActions(
  TRANSITION_INVOICE_JOB,
)
export const setCurrentGeolocPro = generateSimpleAction(SET_CURRENT_GEOLOC_PRO)
export const setEstimatedHourMap = generateSimpleAction(ESTIMATED_HOUR_MAP)
export const GET_PRO_PHONE_NUMBER = createRequestTypes('GET_PRO_PHONE_NUMBER')
export const getProPhoneNumber = generateRequestActions(GET_PRO_PHONE_NUMBER)
export const SHARE_JOB_INFOS = createRequestTypes('SHARE_JOB_INFOS')
export const shareJobInfos = generateRequestActions(SHARE_JOB_INFOS)
export const GET_CDP_PRODUCT_CODE_BY_SLUG = 'GET_CDP_PRODUCT_CODE_BY_SLUG'
export const GET_CDP_PRODUCT_CODE_BY_SLUG_REQ = createRequestTypes(
  GET_CDP_PRODUCT_CODE_BY_SLUG,
)
export const getCdpProductCodeBySlug = generateRequestActions(
  GET_CDP_PRODUCT_CODE_BY_SLUG_REQ,
)

export const GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE =
  'GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE'
export const GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ = createRequestTypes(
  GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE,
)
export const getCdpHelpCenterPostByProductCode = generateRequestActions(
  GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ,
)

export const GET_CDP_HELP_CENTER_POSTS_FOR_JOB =
  'GET_CDP_HELP_CENTER_POSTS_FOR_JOB'
export const getCdpHelpCenterPostsForJob = generateSimpleAction(
  GET_CDP_HELP_CENTER_POSTS_FOR_JOB,
)
