import handleRequest from 'sagas/handleRequest'
import { all, call, put, select } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import { takeLatest, takeEvery } from 'utils/effects'
import client from 'services/httpClient/commonClient'
import notify from 'sagas/notify'

import {
  JOBS_REQ,
  JOB_ACCEPT_TRANSITION_REQ,
  JOB_DECLINE_TRANSITION_REQ,
  UPDATE_JOB,
  GET_RECEIPT_JOB,
  VALIDATE_RECEIPT,
  GET_RECEIPT_SIGNATURE,
  GET_INVOICE_JOB,
  jobs,
  jobAcceptTransition,
  jobDeclineTransition,
  closeAll,
  GET_JOB,
  GET_JOBS,
  getJob,
  updateJob,
  validateReceipt,
  getReceiptJob,
  getReceiptJobFile,
  getReceiptSignature,
  getInvoiceJob,
  transitionInvoiceJob,
  TRANSITION_INVOICE_JOB,
  firmDetails,
  GET_PRO_PHONE_NUMBER,
  getProPhoneNumber,
  SHARE_JOB_INFOS,
  shareJobInfos,
  OPEN_RECEIPT_JOB_FILE,
  GET_RECEIPT_JOB_FILE,
  getCdpHelpCenterPostsForJob,
  getCdpProductCodeBySlug,
  getCdpHelpCenterPostByProductCode,
  GET_CDP_PRODUCT_CODE_BY_SLUG_REQ,
  GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ,
  GET_CDP_HELP_CENTER_POSTS_FOR_JOB,
  GET_DIAGNOSTIC,
  getDiagnostic,
} from 'store/actions'
import { fromPro, fromFirm, fromJob } from 'store/selectors'
import {
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_DECLINED,
  JOBS_PER_PAGE,
  JOBS_PER_PAGE_PENDING_START,
  JOB_STATUS_ORDER,
  STATUS_ORDER_DEFAULT,
  HTTP_CONFLICT_CODE,
  STATUS_JOB_IN_PROGRESS,
  VIRTUAL_STATUS_MISSED,
  STATUSES_FOR_VIRTUAL_STATUS_MISSED,
} from 'constants/job'
import { ENGINE, BILLING } from 'constants/services'
import {
  setCurrentJobId,
  resetAllJobs,
  GET_COUNTER_JOB,
  getCounterJob,
} from './actions'
import CoinDesProsClient from 'services/httpClient/CoinDesProsClient'
import lastIdFromIRI from 'utils/lastIdFromIRI'

function* handleJobRequestByStatusAndPage({ status, page }) {
  const perPage =
    status === STATUS_JOB_IN_PROGRESS
      ? JOBS_PER_PAGE_PENDING_START
      : JOBS_PER_PAGE

  const params = {
    status,
    page,
    perPage,
    [`order[${JOB_STATUS_ORDER[status]}]`]: STATUS_ORDER_DEFAULT,
  }

  yield* handleRequest({
    requestActions: jobs,
    promise: call(client(ENGINE).get, '/engine/jobs', {
      params,
    }),
    actionParams: {
      status,
    },
  })
}

function* handleJobsCountByStatus() {
  yield* handleRequest({
    requestActions: getCounterJob,
    promise: call(client(ENGINE).get, '/engine/jobs-count/statuses/package'),
  })
}

function* handleUpdateJobRequest({ jobData }) {
  try {
    yield* handleRequest({
      requestActions: updateJob,
      promise: call(client(ENGINE).put, jobData['@id'], jobData),
    })
    yield* notify('', 'job.details.update.message')
  } catch (e) {
    console.error(e)
    yield* notify('', 'job.details.update.error_message', 'error')
  }
}

function* handleJobsRequest({ status, page }) {
  yield put(setCurrentJobId(null))

  if (status === VIRTUAL_STATUS_MISSED) {
    yield* STATUSES_FOR_VIRTUAL_STATUS_MISSED.map(function* (stat) {
      yield put(jobs.request({ status: stat, page }))
    })
  } else {
    yield put(jobs.request({ status, page }))
  }
}

function* handleAcceptJobTransitionRequest({ jobIri, data }) {
  try {
    yield* handleRequest({
      requestActions: jobAcceptTransition,
      promise: call(
        client(ENGINE).post,
        `${jobIri}/transition/process_assignment`,
        data,
      ),
    })
    const message = 'job_pro.update.accepted.message'

    yield* notify('', message)

    yield put(push('/my-account/packages/in_progress/pending_realization'))

    yield* handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1, 1)
    yield* handleJobRequestByStatusAndPage(STATUS_PENDING_REALIZATION, 1, 1)
    yield* handleGetJob({ jobIri })

    const firmId = yield select(fromPro.getFirmId)
    const isFirmPendingTestJob = yield select(
      fromFirm.isFirmPendingTestJobSelector,
      firmId,
    )
    if (isFirmPendingTestJob) {
      yield put(firmDetails.request({ id: firmId }))
    }
  } catch (e) {
    if (e.response.status !== HTTP_CONFLICT_CODE) {
      yield* notify('', 'server_error', 'error')
    } else {
      yield* notify('', 'job.details.missed_job.text', 'warning')
    }
    yield put(resetAllJobs())
  }
}

function* handleGetJob({ jobIri }) {
  yield* handleRequest({
    requestActions: getJob,
    promise: call(client(ENGINE).get, jobIri),
  })
}

function* handleGetReceiptJob({ jobIri }) {
  yield* handleRequest({
    requestActions: getReceiptJob,
    promise: call(client(ENGINE).get, `${jobIri}/receipt`),
  })
}

const handleOpenReceiptJobNewWindow = function* ({ jobIri }) {
  yield* handleRequest({
    requestActions: getReceiptJobFile,
    promise: call(client(ENGINE).get, `${jobIri}/receipt`),
  })
}

function handleGetReceiptJobNewWindowSuccess({ payload }) {
  const binary = atob(payload.content.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i)
  }

  const link = document.createElement('a')
  const blob = new Blob([view], { type: 'application/pdf' })
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)

  if (navigator.userAgent.match('CriOS')) {
    link.setAttribute('target', 'blank')
  } else {
    link.setAttribute('download', 'receipt.pdf')
  }

  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function* handleValidateReceipt({ jobData }) {
  try {
    yield* handleRequest({
      requestActions: validateReceipt,
      promise: call(client(ENGINE).put, jobData['@id'], jobData),
    })
    yield* handleRequest({
      requestActions: getReceiptJob,
      promise: call(client(ENGINE).get, `${jobData['@id']}/receipt`),
    })
    yield* notify('', 'job.details.validate.receipt.message')
    yield* handleRequest({
      requestActions: getReceiptSignature,
      promise: call(client(ENGINE).get, `${jobData['@id']}/receipt/signature`),
    })
  } catch (e) {
    yield* notify('', 'job.details.validate.receipt.signature.error', 'error')
  }
}

function* handleGetReceiptSignature({ jobIri }) {
  try {
    yield* handleRequest({
      requestActions: getReceiptSignature,
      promise: call(client(ENGINE).get, `${jobIri}/receipt/signature`),
    })
  } catch (e) {
    yield* notify('', 'job.details.validate.receipt.signature.error', 'error')
  }
}

function* handleGetInvoiceJob({ invoiceId }) {
  yield* handleRequest({
    requestActions: getInvoiceJob,
    promise: call(
      client(BILLING).get,
      `purchase-invoices/${invoiceId}/content`,
    ),
  })
}

function* handleTransitionInvoiceJob({ invoiceId, transition, data = {} }) {
  try {
    yield* handleRequest({
      requestActions: transitionInvoiceJob,
      promise: call(
        client(BILLING).post,
        `/purchase-invoices/${invoiceId}/transition/${transition}`,
        data,
      ),
    })
  } catch (e) {
    yield* notify('', 'job_pro.invoice.transition.error', 'error')
  }
}

function* handleDeclineJobTransitionRequest({ jobIri, data }) {
  yield put(closeAll())
  try {
    yield* handleRequest({
      requestActions: jobDeclineTransition,
      promise: call(
        client(ENGINE).post,
        `${jobIri}/transition/process_decline`,
        data,
      ),
    })

    yield put(push('/my-account/packages/pending_answer'))
    yield* notify('', 'job.transition.decline.success_message')

    yield* handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1)
    yield* handleJobRequestByStatusAndPage(STATUS_JOB_DECLINED, 1)
    yield* handleGetJob({ jobIri })
  } catch (e) {
    if (e.response.status !== HTTP_CONFLICT_CODE) {
      yield* notify('', 'server_error', 'error')
    } else {
      yield* notify('', 'job.details.missed_job.text', 'warning')
    }
    yield* handleJobRequestByStatusAndPage(STATUS_PENDING_ANSWER, 1)
    yield* handleGetJob({ jobIri })
  }
}

function* handleGetProPhoneNumber({ payload }) {
  yield* handleRequest({
    requestActions: getProPhoneNumber,
    promise: call(client(ENGINE).get, `/engine/jobs/${payload}/conversations`),
  })
}

function* handleShareJobInfos({ jobId, data }) {
  try {
    yield* handleRequest({
      requestActions: shareJobInfos,
      promise: call(client(ENGINE).post, `/engine/jobs/${jobId}/share`, data),
    })
  } catch (e) {
    yield* notify('', 'server_error', 'error')
  }
}

function* handleGetJobReqSuccess({ payload }) {
  yield put(getCdpHelpCenterPostsForJob({ jobIri: payload['@id'] }))
}

function* handleGetCdpProductCodeRequest({ slug }) {
  yield* handleRequest({
    requestActions: getCdpProductCodeBySlug,
    promise: CoinDesProsClient.getProductCodeBySlug(slug),
    actionParams: {},
    checkTokens: false,
  })
}

function* handleGetCdpProductCodeSuccess({ payload }) {
  yield put(
    getCdpHelpCenterPostByProductCode.request({
      productCodeId: payload[0].id,
      productCodeName: payload[0].name,
    }),
  )
}

function* handleGetCdpHelpCenterPostRequest({
  productCodeId,
  productCodeName,
}) {
  yield* handleRequest({
    requestActions: getCdpHelpCenterPostByProductCode,
    promise: CoinDesProsClient.getHelpCenterPostByProductCode(productCodeId),
    actionParams: { productCodeName },
    checkTokens: false,
  })
}

function* handleGetCdpHelpCenterPostsForJob({ jobIri }) {
  const job = yield select(fromJob.getJob, jobIri)
  yield all(
    job.products.map(product =>
      put(
        getCdpProductCodeBySlug.request({
          slug: lastIdFromIRI(product['@id']),
        }),
      ),
    ),
  )
}

function* handleGetDiagnostic({ jobIri }) {
  yield* handleRequest({
    requestActions: getDiagnostic,
    promise: call(client(ENGINE).get, `${jobIri}/diagnostic`),
  })
}

export default function* () {
  yield all([
    takeLatest(GET_JOB.REQUEST, handleGetJob),
    takeLatest(GET_COUNTER_JOB.REQUEST, handleJobsCountByStatus),
    takeLatest(GET_JOBS, handleJobsRequest),
    takeEvery(JOBS_REQ.REQUEST, handleJobRequestByStatusAndPage),
    takeLatest(
      JOB_ACCEPT_TRANSITION_REQ.REQUEST,
      handleAcceptJobTransitionRequest,
    ),
    takeLatest(
      JOB_DECLINE_TRANSITION_REQ.REQUEST,
      handleDeclineJobTransitionRequest,
    ),
    takeLatest(UPDATE_JOB.REQUEST, handleUpdateJobRequest),
    takeLatest(VALIDATE_RECEIPT.REQUEST, handleValidateReceipt),
    takeLatest(GET_RECEIPT_JOB.REQUEST, handleGetReceiptJob),
    takeLatest(OPEN_RECEIPT_JOB_FILE, handleOpenReceiptJobNewWindow),
    takeLatest(
      GET_RECEIPT_JOB_FILE.SUCCESS,
      handleGetReceiptJobNewWindowSuccess,
    ),
    takeLatest(GET_RECEIPT_SIGNATURE.REQUEST, handleGetReceiptSignature),
    takeLatest(GET_INVOICE_JOB.REQUEST, handleGetInvoiceJob),
    takeLatest(TRANSITION_INVOICE_JOB.REQUEST, handleTransitionInvoiceJob),
    takeLatest(GET_PRO_PHONE_NUMBER.REQUEST, handleGetProPhoneNumber),
    takeLatest(SHARE_JOB_INFOS.REQUEST, handleShareJobInfos),
    takeLatest(GET_JOB.SUCCESS, handleGetJobReqSuccess),
    takeEvery(
      GET_CDP_PRODUCT_CODE_BY_SLUG_REQ.REQUEST,
      handleGetCdpProductCodeRequest,
    ),
    takeEvery(
      GET_CDP_PRODUCT_CODE_BY_SLUG_REQ.SUCCESS,
      handleGetCdpProductCodeSuccess,
    ),
    takeEvery(
      GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ.REQUEST,
      handleGetCdpHelpCenterPostRequest,
    ),
    takeLatest(
      GET_CDP_HELP_CENTER_POSTS_FOR_JOB,
      handleGetCdpHelpCenterPostsForJob,
    ),
    takeLatest(GET_DIAGNOSTIC.REQUEST, handleGetDiagnostic),
  ])
}
